import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/theme/src/components/MarkdownPagesLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Image alt="About" src="/images/about.jpg" position="right" mdxType="Image" />
    <h1>{`About Me`}</h1>
    <p>{`Hey, and welcome to my site!`}</p>
    <p>{`I cook stuff and take photos. Mostly Asian and vegetarian cuisine.
I also sometimes do sports, read and sit in front of my computer far too much.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      